import React from 'react';
import styled from "styled-components"

import PageTemplate from '../templates/pageTemplate';

const ReferencesPage = () => (
  <PageTemplate
    title="Ketogenic Diet Scientific References" 
    description="Looking for more information about the keto diet and how it can help you? Check out these scienctific studies."
  >
    <H1>Scientific references</H1>
    <Paragraph>
      Looking for more information on the ketogenic diet? Check out these scienctific studies for more information about dieting, keto, obseisty, and more.
      Learn more about ketosis and how the keto diet can help you loose weight, have more energy, and be healthier.
    </Paragraph>
    <ReferencesList/>
  </PageTemplate>
)

const ReferencesList = () => (
  <StyledList>
    <li>1. Lancet. 2014 Aug 30;384(9945):766-81.</li>
    <li>2. Res Q Exerc Sport. 2013 Sep;84(3):275-86.</li>
    <li>3. J Am Diet Assoc. 2005 Apr;105(4):573-80.</li>
    <li>4. Lancet. 2004 Sep 4-10;364(9437):897-9.</li>
    <li>5. J Clin Endocrinol Metab. 2008 Sep;93(9):3373-80.</li>
    <li>6. Diabetes Care. 1999 Jun;22(6):889-95.</li>
    <li>7. Diabetes Care. 2002 Mar;25(3):425-30.</li>
    <li>8. Br J Nutr. 2007 Feb;97(2):405-10.</li>
    <li>9. Am J Clin Nutr. 2005 Jun;81(6):1298-306.</li>
    <li>10. Ann Intern Med. 2005 Mar 15;142(6):403-11.</li>
    <li>11. Am J Clin Nutr. 2005 Apr;81(4):762-72.</li>
    <li>12. Am J Clin Nutr. 2003 Jul;78(1):31-9.</li>
    <li>13. Int J Obes Relat Metab Disord. 2004 May;28(5):661-70.</li>
    <li>14. Int J Obes Relat Metab Disord. 2000 Apr;24(4):492-6.</li>
    <li>15. J Clin Endocrinol Metab. 2008 Sep;93(9):3373-80.</li>
    <li>16. Ann Nutr Metab. 2007;51(5):428-32.</li>
    <li>17. Int J Obes Relat Metab Disord. 1996 Dec;20(12):1067-72.</li>
    <li>18. Am J Clin Nutr. 2006 Oct;84(4):813-21.</li>
    <li>19. Send to JAMA. 2012 Jan 4;307(1):47-55.</li>
    <li>20. http://edition.cnn.com/2010/HEALTH/11/08/twinkie.diet.professor/index.html</li>
    <li>21. McDonald, L. (1998). The Ketogenic Diet: A Complete Guide for the Dieter and Practitioner (1st
        ed.)
    </li>
    <li>22. Nutr Metab (Lond). 2004; 1: 13.</li>
    <li>23. JAMA. 2014 Jun 4;311(21):2167-8.</li>
    <li>24. Nat Clin Pract Endocrinol Metab. 2006 Aug;2(8):447-58.</li>
    <li>25. BMJ. 2013 Apr 15;346:f1050.</li>
    <li>26. Eat Behav. 2013 Jan;14(1):69-72.</li>
    <li>27. Eat Behav. 2010 Aug;11(3):175-9.</li>
    <li>28. J Clin Invest. 1967 Oct; 46(10): 1589–1595.</li>
    <li>29. Br J Nutr. 2013 Oct;110(7):1178-87.</li>
    <li>30. N Engl J Med. 2003 May 22;348(21):2074-81.</li>
    <li>31. N Engl J Med 2003; 348:2074-2081</li>
    <li>32. J Diabetes Complications. 2015 Sep-Oct;29(7):950-4.</li>
    <li>33. PLoS One. 2013 Nov 27;8(11):e79324.</li>
    <li>34. Arch Intern Med. 2004 Oct 25;164(19):2141-6.</li>
    <li>35. J Nutr. 2006 Feb;136(2):384-9.</li>
    <li>36. N Engl J Med. 2003 May 22;348(21):2082-90.</li>
    <li>37. Am J Clin Nutr. 2009 Jul;90(1):23-32</li>
    <li>38. Ann Intern Med. 2005 Mar 15;142(6):403-11.</li>
    <li>39. Nutr Metab (Lond). 2005; 2: 34.</li>
    <li>40. Biol Psychiatry. 2004 Dec 15;56(12):981-3.</li>
    <li>41. BMC Neurosci. 2006 Apr 3;7:29.</li>
    <li>42. J Cancer Res Ther. 2009 Sep;5 Suppl 1:S7-15.</li>
    <li>43. J Am Coll Nutr. 1995 Apr;14(2):202-8.</li>
  </StyledList>
)

const H1 = styled.h1`
  color: #fff;
`;

const Paragraph = styled.p`
  color: #fff;
`;

const StyledList = styled.ul`
  color: #fff;
  list-style: none;
  padding-left: 0;
`

export default ReferencesPage;